<template>
  <Vue3DraggableResizable :w="870" :h="400" :x="200" :y="500">
    <div class="finder-window">
      <!-- Sidebar -->
      <div class="sidebar">
        <div class="sidebar-header">
          <div class="finder-buttons">
            <div class="button close"></div>
            <div class="button minimize"></div>
            <div class="button zoom"></div>
          </div>
        </div>
        <div class="sidebar-content">
          <div class="sidebar-content-item">
            <font-awesome-icon :icon="['fas', 'display']" /> 데스크탑
          </div>
          <div class="sidebar-content-item">
            <font-awesome-icon :icon="['faL', 'file']" /> 문서
          </div>
          <div class="sidebar-content-item">
            <font-awesome-icon :icon="['faR', 'house']" /> guest
          </div>
        </div>
      </div>

      <div class="finder-content">
        <div class="finder-header">
          {{ title }}
        </div>
        <div class="main-content-wrapper">
          <div class="main-content">
            <ImageFileComp title="테스투"/>
            <ImageFileComp title="테스투"/>
            <ImageFileComp title="테스투"/>
            <ImageFileComp title="테스투"/>
            <ImageFileComp title="테스투"/>
            <ImageFileComp title="테스투"/>
            <ImageFileComp title="테스투"/>
            <ImageFileComp title="테스투"/>
            <ImageFileComp title="테스투"/>
          </div>
        </div>
      </div>
    </div>
  </Vue3DraggableResizable>
</template>

<script>
import Vue3DraggableResizable from "vue3-draggable-resizable";
import ImageFileComp from "@/components/ImageFileComp.vue";

export default {
  props: {
    title : String
  },
  components: {ImageFileComp, Vue3DraggableResizable },
}
</script>

<style scoped>
.finder-window {
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
}

.sidebar-header {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 16px;
}

.sidebar-content {
  padding: 10px;
}

.sidebar-content-item {
  width: 100%;
  margin-bottom: 10px;
  color: #525252;
  font-size: smaller;
}

.finder-header {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  color: #525252;
  padding-left: 50px;
  font-weight: bold;
}

.finder-buttons {
  display: flex;
}

.button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 8px;
}

.button.close {
  background-color: #ff5f56;
}

.button.minimize {
  background-color: #ffbd2e;
}

.button.zoom {
  background-color: #27c93f;
}

.finder-content {
  flex: 1;
}

.sidebar {
  width: 130px;
  background-color: #dddddd;
  border-right: 1px solid #ccc;
}

.main-content-wrapper {
  overflow: scroll;
  height: calc(100% - 55px);
  width: 100%;
}

.main-content {
  flex: 1;
  padding: 16px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
}
</style>