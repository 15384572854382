<template>
  <FolderComp v-for="folder in desktopConstant.FOLDERS" :key="folder" :title="folder.TITLE" />
  <FinderWindow title="안녕하세요"
                :style="{ zIndex: divs['finder'].zIndex }"
                @click="increaseZIndex('finder')"/>
  <TerminalWindow :style="{ zIndex: divs['terminal'].zIndex }"
                  @click="increaseZIndex('terminal')"/>
</template>

<script>
import TerminalWindow from "@/components/TerminalWindow.vue";
import FolderComp from "@/components/FolderComp.vue";
import desktopConstant from "@/constants/desktop";
import FinderWindow from "@/components/FinderWindow.vue";
export default {
  name: 'App',
  computed: {
    desktopConstant() {
      return desktopConstant
    }
  },
  components: {
    FinderWindow,
    FolderComp,
    TerminalWindow
  },
  methods: {
    increaseZIndex(index) {
      this.divs[index].zIndex = ++this.zIndexMax;
    }
  },
  data() {
    return {
      zIndexMax: 0,
      divs: {
        'finder': {zIndex: 1},
        'terminal' : {zIndex: 1},
      }
    }
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background: radial-gradient(circle, #c2e5f2, #4d8eb9, #00274c) no-repeat fixed;
}
</style>
