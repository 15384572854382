export default {
    BANNER: ` _    _ _____   _    _ _____ _      _      ___  ___  ___  ______________   __  _____  _____  _____ _   _
| |  | |  ___| | |  | |_   _| |    | |     |  \\/  | / _ \\ | ___ \\ ___ \\ \\ / / /  ___||  _  ||  _  | \\ | |
| |  | | |__   | |  | | | | | |    | |     | .  . |/ /_\\ \\| |_/ / |_/ /\\ V /  \\ \`--. | | | || | | |  \\| |
| |/\\| |  __|  | |/\\| | | | | |    | |     | |\\/| ||  _  ||    /|    /  \\ /    \`--. \\| | | || | | | . \` |
\\  /\\  / |___  \\  /\\  /_| |_| |____| |____ | |  | || | | || |\\ \\| |\\ \\  | |   /\\__/ /\\ \\_/ /\\ \\_/ / |\\  |
 \\/  \\/\\____/   \\/  \\/ \\___/\\_____/\\_____/ \\_|  |_/\\_| |_/\\_| \\_\\_| \\_| \\_/   \\____/  \\___/  \\___/\\_| \\_/

 ₍ ᐢ๑-˔-ᐢ₎ ♡         ♡ ૮₍๑^_^ ๑ ₎ა
( っ /￣￣￣/           \\￣￣￣￣\\ ⊂ )
(´＼/＿＿＿/)           (\\＿＿＿ \\／\`)

 일 시 : 2024년 9월 21일 토요일 12:00
 장 소 : 경기 성남시 분당구 판교역로146번길 2 그래비티 타워, 지하 1층`,
    COMMENTS: {
        HEADER:
`+--------+----------+------
|  작성자  |  작성일시  |  내용
+--------+----------+------`
    },
    COMMANDS: {
        HELP: {
            COMMAND: "help",
            TEXT: `안녕하세요? 아래 명령어로 여러가지 동작을 수행할 수 있답니다!

* help : 도움말
* comment add -u 닉네임 -m 안녕하세요 : '닉네임'이라는 이름으로 '안녕하세요'라는 내용으로 댓글이 작성됩니다. 내용은 자유롭게 작성할 수 있어요!
* comment ls : 작성된 댓글을 모두 출력합니다.`
        },
        ADD_COMMENT: {
            COMMAND: "comment add",
        },
        SHOW_COMMENTS: {
            COMMAND: "comment ls",
        },
        SCHEDULE: {}
    }
}