<template>
  <div class="wrapper">
    <div class="icon" @click="handleMacFolderButtonClick"></div>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props : {
    title : String
  },
  methods: {
    handleMacFolderButtonClick() {
      // Mac folder 버튼이 클릭될 때 수행할 동작 추가
      console.log('Mac folder button clicked!');
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100px;
  height: 100px;
  justify-items: center;
  padding: 20px;
}
.icon {
  width: 80px;
  height: 80px;
  background: url(@/assets/folder.png) no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  margin: auto;
}
.title {
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: #dddddd;
}
</style>