<template>
  <Vue3DraggableResizable :w="870" :h="400" :x="200" :y="100">
    <div id="terminal">
      <div class="title-bar">
        <div class="buttons">
          <div class="button close"></div>
          <div class="button minimize"></div>
          <div class="button maximize"></div>
        </div>
        <div class="title">-zsh</div>
      </div>
      <div class="content-wrapper" ref="contentWrapper">
        <div class="content">
          <div class="line">
            <pre>{{ terminalWindowConstant.BANNER }}</pre>
          </div>
          <div v-for="(line, index) in terminalLines" :key="index" class="line">
            <span v-if="line.isCommand" class="user">guest@jihyun.1hoon.dev:</span>
            <span v-if="line.isCommand" class="directory">~</span>
            <span v-if="line.isCommand" class="delimiter">$</span>
            <span v-if="line.isCommand" class="command">{{ line.value }}</span>
            <pre v-if="!line.isCommand" class="command" style="display: block">{{ line.value }}</pre>
          </div>
        </div>
        <div ref="bottomEl" style="height: 1px;"></div>
      </div>
        <div class="prompt">
          <span class="user">guest@jihyun.1hoon.dev:</span><span class="directory">~</span><span class="delimiter">$</span>
          <input v-model="command" @keyup.enter="executeCommand" />
        </div>
    </div>
  </Vue3DraggableResizable>
</template>

<script>
import Vue3DraggableResizable from 'vue3-draggable-resizable'
import terminalWindowConstant from "@/constants/terminalWindow";
import axios from "axios";
import dayjs from "dayjs";
export default {
  computed: {
    terminalWindowConstant() {
      return terminalWindowConstant
    }
  },
  components: { Vue3DraggableResizable },
  data() {
    return {
      terminalLines: [],
      command: '',
    };
  },
  methods: {
    async executeCommand() {
      // 현재 입력된 명령어를 terminalLines에 추가
      this.terminalLines.push({ isCommand : true, value : this.command});

      // 여기에서 명령어를 처리하고 결과를 output에 저장
      // 예를 들어, API 호출이나 계산 등을 수행할 수 있습니다.
      if (this.command.trim() === terminalWindowConstant.COMMANDS.HELP.COMMAND) {
        await this.printHelp();
      }
      if (this.command.trim().startsWith(terminalWindowConstant.COMMANDS.SHOW_COMMENTS.COMMAND)) {
        await axios.get("http://1hoon.iptime.org:814/api/v1.0/comments")
        .then((res) => {
          this.printCommentHeader();
          this.printComments(res.data);
        })

      }
      if (this.command.trim().startsWith(terminalWindowConstant.COMMANDS.ADD_COMMENT.COMMAND)) {
        let nicknamePosition = this.command.indexOf('-u');
        let messagePosition = this.command.indexOf('-m');
        const nickname = this.command.substring(messagePosition, nicknamePosition).match(/-u\s([a-zA-Z0-9가-힣]*)/)[1]
        const message = this.command.substring(messagePosition).match(/-m\s([a-zA-Z0-9가-힣]*)/)[1]

        await axios.post("http://1hoon.iptime.org:814/api/v1.0/comments", {
          "nickname": nickname, "message": message
        })
        .then((res) => {
          this.printCommentHeader();
          this.printComments(res.data);
        })
      }

      this.command = '';
      this.scrollToBottom();
    },

    printHelp() {
      this.terminalLines.push({ isCommand : false, value : terminalWindowConstant.COMMANDS.HELP.TEXT});
    },

    printCommentHeader() {
      this.terminalLines.push({ isCommand : false, value : terminalWindowConstant.COMMENTS.HEADER});
    },

    printComments(comments) {
      const content = comments.map((comment) => {
        return comment.nickname + ' | ' + this.getTimeString(comment.createdAt) + ` | ` + comment.message;
      }).join("\n");

      this.terminalLines.push({ isCommand : false, value : content});
    },

    scrollToBottom() {
      // contentWrapper 영역을 하단으로 스크롤
      const contentWrapper = this.$refs.contentWrapper;
      contentWrapper.scrollTop = contentWrapper.scrollHeight;
    },

    getTimeString(dateString) {
      return dayjs(dateString).format('YYYY-MM-DD')
    },
  },
}
</script>

<style scoped>
#terminal {
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: #1e1e1e;
  height: 100%;
}

.title-bar {
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  background-color: #dddddd; /* 라이트 모드 색상 */
  color: #1e1e1e;
  align-items: center;
}

.buttons {
  padding-left: 13px;
  display: flex;
  margin-right: auto;
}

.button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.button.close { background-color: #fc615d; }
.button.minimize { background-color: #fdb82a; }
.button.maximize { background-color: #34c749; }

.title {
  display: flex;
  margin-right: auto;
  color: #525252;
}

.content-wrapper {
  height: calc(100% - 70px);
  overflow-y: scroll;
}

.content {
  color: #dddddd;
  padding: 20px;
}

.prompt {
  display: flex;
  padding-left: 20px;
}

.user {
  color: #00ff00;
}

.directory {
  color: #87cefa;
}

.delimiter {
  color: #fff;
  margin-right: 5px;
}

.command {
  white-space: pre-wrap;
}

input {
  background-color: transparent;
  border: none;
  outline: none;
  color: #dddddd;
  width: 100%;
  font-size: 16px;
}
</style>