<template>
  <div class="wrapper">
    <div class="icon-wrapper" @click="handleMacFolderButtonClick">
      <div class="icon"/>
    </div>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props : {
    title : String
  },
  methods: {
    handleMacFolderButtonClick() {
      // Mac folder 버튼이 클릭될 때 수행할 동작 추가
      console.log('Mac folder button clicked!');
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100px;
  height: 100px;
  justify-items: center;
  padding: 20px;
}
.icon-wrapper {
  width: 80px;
  height: 80px;
  margin: auto;
  cursor: pointer;
  display: flex;
  border: 3px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.icon {
  width: 78px;
  height: 78px;
  margin: auto;
  background: url(@/assets/background.jpg) no-repeat;
  background-size: cover;
  border: 1px solid #cbcbcb;
}
.title {
  text-align: center;
  padding-top: 10px;
  color: #525252;
  font-size: smaller;
}
</style>